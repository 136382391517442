import { Background } from "../UTILITIES/Background";
import { Navigation } from "../UTILITIES/Navigation";


export function Home() {
    return <div className="roboto relative">
        <Navigation />
        <Background />
        <div className="main"></div>

    </div>
}