import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router";
import 'react-library/src/App.css'
import { Home } from './PAGES/Home';
import { About } from './PAGES/About';
import { Events } from './PAGES/Events';

// Ensure you're getting the 'root' element correctly
const root = ReactDOM.createRoot(document.getElementById('root'));

// Now, correctly call createRouter and pass the routes as a prop.
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/events" element={<Events />} />
    </Routes>
  </BrowserRouter>
);