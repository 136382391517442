import { Background } from "../UTILITIES/Background";
import { Navigation } from "../UTILITIES/Navigation";

export function Events() {
    return <div className="poppins">
        <Navigation />
        <Background />
        <div className="main">
            {/*  */}
        </div>
    </div>
}