import { Clickable } from './Clickable'
import img1 from '../IMAGES/sbncdems-logo.jpg'
import { RiMenuFill } from "react-icons/ri";
import { RiCloseLargeFill } from "react-icons/ri";
import { MdOutlineArrowOutward } from "react-icons/md";
import { useState } from 'react';
import { useNavigate } from 'react-router';

export function Navigation() {
    const [showNav, setShowNav] = useState(false);
    const navigate = useNavigate();

    return <div style={{ backgroundColor: "#1A3448" }} className='vertical-center separate-h roboto'>
        <Clickable><img src={img1} style={{ height: 70, width: 70 }} className='full-width' /></Clickable>
        <div className='p-h'>
            <Clickable onPress={() => { setShowNav(true) }}><RiMenuFill className='text-md white' /></Clickable>
        </div>

        {/*  */}

        {
            showNav && <div style={{ backgroundColor: '#1A3448' }} className='slide-right fixed full-height full-width top left right bottom bg-white separate-v'>
                <div className='gap'>
                    <div className='separate-h'>
                        <div></div>
                        <div className='p'><Clickable onPress={() => { setShowNav(false) }}><RiCloseLargeFill className='text-md' color='white' /></Clickable></div>
                    </div>
                    <div>
                        <Clickable onPress={() => { navigate('/') }}>
                            <div className='separate-h white p border-b-1-white'>
                                <p className='text-md'>Home</p>
                                <MdOutlineArrowOutward className='text-md' />
                            </div>
                        </Clickable>
                        <Clickable onPress={() => { navigate('/events') }}>
                            <div className='separate-h white p border-b-1-white'>
                                <p className='text-md'>Events</p>
                                <MdOutlineArrowOutward className='text-md' />
                            </div>
                        </Clickable>
                        <Clickable onPress={() => { navigate('/about') }}>
                            <div className='separate-h white p border-b-1-white'>
                                <p className='text-md'>About Us</p>
                                <MdOutlineArrowOutward className='text-md' />
                            </div>
                        </Clickable>
                    </div>
                </div>

                <div>
                    <div className=''>
                        <p className='white text-xs p-h'>The South Bay - National City Democrats promote Democratic values, encourage informed political participation, host forums for candidates and issues, and endorse candidates and ballot measures.</p>
                    </div>
                    <br />
                    <p className=' p-sm white text-xs text-center'>&copy; copyright South Bay National City Democrats. All Rights Reserved 2024.</p>
                </div>
            </div>
        }
    </div>
}