import { Navigation } from "../UTILITIES/Navigation";
import { Divider } from '../UTILITIES/Divider'

import img1 from '../IMAGES/young-democrats.jpg'
import img2 from '../IMAGES/membership.jpg'
import img3 from '../IMAGES/officers.jpg'
import img4 from '../IMAGES/election.jpg'
import img5 from '../IMAGES/meetings.jpg'
import { Background } from "../UTILITIES/Background";

export function About() {
    return <div className='poppins'>
        <Navigation />
        <Background />
        <div className="main">
            <div className="p-lg">
                <h1 className="text-xxl spacing-xs slide-right line-sm">South Bay National City Democrats</h1>
            </div>
            <div
                className="row p-md box gap-md border-b-1-black"
                style={{ position: 'relative' }} // Ensure the parent container has relative positioning
            >
                <div className="md1 lg2 xl2">
                    <p className="thin">
                        The South Bay - National City Democrats are dedicated to fostering the ideals and principles of the Democratic Party while encouraging thoughtful and informed participation in the political process. They work to educate and advise their members, as well as the general public, on critical political issues, including support or opposition to local and state propositions. The organization serves as a platform for Democratic candidates in partisan races and ensures that all candidates in non-partisan local races have the opportunity to engage with the community. Additionally, they provide forums to disseminate information about pending ballot proposals, analyze emerging community and social issues, and facilitate discussions on matters that affect the residents of National City and the South Bay region. A key function of the group is their endorsement process for candidates and ballot measures, ensuring that their support aligns with the values and priorities of their community.
                    </p>
                </div>
                <div
                    className="md1 lg1 xl2"
                    style={{
                        position: 'sticky',
                        top: '20px', // Keeps the image sticky when scrolling
                    }}
                >
                    <img src={img1} style={{ borderRadius: '10px' }} className="full-width radius" />
                </div>
            </div>

            <div className="p-md row box gap-md border-b-1-black">
                <div className="md1 lg2">
                    <h1 className="text-md light-blue thin">Membership</h1>
                    <br />
                    <p className="thin">
                        Membership in the South Bay - National City Democrats is open to anyone registered as a Democrat or intending to register as one. Members who pay annual dues and have been active for at least 60 days may vote and hold office. Annual dues are $15 for individuals, with reduced rates of $5 for seniors, students, or those with limited income. Dues are payable upon joining and by January 1st each year, with a two-month grace period until March 1st to maintain good standing. New members joining between March 1st and November 30th will have prorated dues, and those joining after December 1st are covered through the subsequent year. Dues waivers may be requested due to financial hardship, and club officers may waive first-year dues to encourage membership. Voting by proxy is not allowed, but absentee ballots submitted via email are accepted for bylaw changes, resolutions, endorsements, and elections.
                    </p>
                </div>
                <div className="md1 lg1 xl2">
                    <img src={img2} style={{ borderRadius: '10px' }} className="full-width radius" />
                </div>
            </div>
            <div className="p-md row box gap-md">
                <div className="md1 lg2">
                    <h1 className="text-md light-blue thin">Officers</h1>
                    <br />
                    <p className="thin">
                        The South Bay - National City Democrats elect officers annually, including a president, vice president, secretary, and treasurer, with no term limits. Officers serve from the close of the second scheduled general meeting of one calendar year until new officers assume their roles after the second meeting of the following year.
                    </p>
                </div>
                <div className="md1 lg1 xl2">
                    <img src={img3} style={{ borderRadius: '10px' }} className="full-width radius" />
                </div>
            </div>
            <div className="row p-md box border-b-1-black">
                <div className="md2">
                    <h3 className="blue thin">President</h3>
                    <p className="thin">Oversees public relations, presides over club and executive committee meetings, manages the club newsletter, and handles other presidential duties.</p>
                    <br />
                    <h3 className="blue thin">Vice President</h3>
                    <p className="thin">Oversees public relations, presides over club and executive committee meetings, manages the club newsletter, and handles other presidential duties.</p>
                    <br />
                    <h3 className="blue thin">Secretary</h3>
                    <p className="thin">Maintains minutes of all meetings, manages correspondence and club records, including membership lists, handles club communications, and ensures the bylaws are up to date.</p>
                    <br />
                    <h3 className="blue thin">Treasurer</h3>
                    <p className="thin">Manages all club funds, prepares monthly financial reports, facilitates fundraising, collects dues, and ensures financial records are accessible to outgoing and incoming officers during January board meetings in election years.</p>
                    <br />
                    <h3 className="blue thin">Past President/Board Member-at-Large</h3>
                    <p className="thin">The immediate past president serves on the executive board. If the past president cannot or chooses not to serve, the board may appoint a member-at-large, subject to majority ratification by the general membership at the next meeting.</p>
                    <br />
                    <Divider />
                    <br />
                    <p className="thin">
                        If an officer misses three consecutive general meetings without valid cause or fails to fulfill their duties, the executive committee may declare the position vacant. Vacancies are filled by the executive committee’s appointment, subject to membership ratification at the next general meeting, with prior notification as outlined in the bylaws. If the membership rejects the appointee, nominations will be taken from the floor, and if multiple candidates are nominated, a secret ballot election will determine the new officer, with the candidate receiving the majority of votes elected.
                    </p>
                    <br />
                    <Divider />
                    <br />
                    <p className="thin">The president may appoint a parliamentarian and other officers not specified in the bylaws as needed for the club's well-being. Once ratified by a vote at a general meeting with prior notice and conducted by secret ballot, these officers gain full voting rights on the board and serve until the next election cycle, but they can be removed following Section 3 of this Article. Additionally, if the SBNCD qualifies for pre-primary endorsement caucus delegates, the executive board will propose a list of delegate(s) for membership ratification. Any eligible club member in good standing who meets residency requirements and is not already a DSCC delegate may be nominated from the floor. A vote will then determine the delegate, with the individual receiving the majority representing the club.</p>
                </div>
            </div>
            <div className="p-md row box gap-md border-b-1-black">
                <div className="md1 lg2">
                    <h1 className="text-md light-blue thin">Elections</h1>
                    <br />
                    <p className="thin">
                        At the first general meeting of the year, the president appoints four members to the elections committee, chaired by the past president, who oversees the election process and accepts officer nominations from the floor. Nominees must be members in good standing, and elections are held at the meeting if a quorum (as defined in Article V, Section 2) is present. Any member in good standing may move for officer elections to be conducted by secret ballot, which proceeds if seconded, debated, and approved by a majority vote. The elections committee counts the ballots and announces the results. A nominee receiving a majority vote is elected; if no majority is reached, a run-off is held between the top two candidates. In uncontested races, candidates are elected by acclamation. Only members in good standing at the first general meeting of the year may vote.
                    </p>
                </div>
                <div className="md1 lg1 xl2">
                    <img src={img4} style={{ borderRadius: '10px' }} className="full-width radius" />
                </div>
            </div>
            <div className="p-md row box gap-md border-b-1-black">
                <div className="md1 lg2">
                    <h1 className="text-md light-blue thin">Meetings</h1>
                    <br />
                    <p className="thin">
                        The South Bay - National City Democrats will hold at least seven general meetings annually, including four early in the calendar year, with no meeting in December unless an emergency arises. General meetings are scheduled by the executive committee, and a quorum—defined as twice the number of officers plus one—is required to conduct business. Meetings follow Robert's Rules of Order, Newly Revised for any parliamentary questions not addressed in the bylaws. Members in good standing will receive an email notification at least 14 days before each meeting, including the date, time, location, and agenda. If the president cannot send the notification, this responsibility passes to the vice president. Agenda items may include bylaw changes, resolutions, endorsements, or elections.
                    </p>
                </div>
                <div className="md1 lg1 xl2">
                    <img src={img5} style={{ borderRadius: '10px' }} className="full-width radius" />
                </div>
            </div>
        </div>
    </div>
}